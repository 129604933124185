import Actions from './register';
import ApiDirectory from '../../apiDirectory';


export default {
  hardReload: () => ({
    type: Actions.troubleshoot.hardReload.COMMIT
  }),
  checkVersion: () => ({
    type: Actions.troubleshoot.versionCheck.REQUEST,
    payload: {},
    meta: {
      offline: {
        effect: {url: ApiDirectory.troubleshoot.checkVersion(), method: 'POST'},
        commit: {type: Actions.troubleshoot.versionCheck.COMMIT, meta: {}}
      }
    }
  })
};