import { Router, Route } from 'preact-router';
import { connect } from 'react-redux';
import { Component } from 'preact';

import { FitterAccess, AccessWrapper } from "../lib/access";

import Loading from './common/Loading';
import VersionCheck from './utility/VersionCheck';
import { navHelper } from "./Nav";
import Header from './Header';
import HomePage from '../routes/HomePage';
import AppointmentListPage from '../routes/AppointmentListPage';
import AppointmentPage from '../routes/AppointmentPage';
import PaymentFlowPage from '../routes/PaymentFlowPage';
import CarpetCollectionPage from '../routes/CarpetCollectionPage';
import AddCustomerPage from '../routes/AddCustomerPage'
import LogoutPage from '../routes/LogoutPage';
import Footer from "./Footer";


const handlePageReload = e => {
  window.location.reload();
};


class App extends Component {
  constructor(props) {
    super(props);

    document.addEventListener("backbutton", navHelper.triggerLink, false);
  };

  render({ready}) {
    if (!ready) {
      return (
        <div id="app">
          <Loading/>
          <button onClick={handlePageReload}>Refresh</button>
        </div>
      );
    }

    return (
      <div id="app">
        {/*<VersionCheck/>*/}
        <Header/>
        <Router onChange={() => navHelper.setLink('/')}>
          <AppointmentPage path="/appointment/:id"/>
          <PaymentFlowPage path="/paymentflow/:id/complete" complete={true} />
          <PaymentFlowPage path="/paymentflow/:id/card-check" checkCard={true} />
          <PaymentFlowPage path="/paymentflow/:id" />
          <AppointmentListPage path="/day/:date"/>
          <Route component={props => (
            <AccessWrapper component={CarpetCollectionPage} {...FitterAccess} {...props}/>
          )} path="/collect/:date"/>
          <LogoutPage path="/logout"/>
          <AddCustomerPage path="/customer/add" />
          <HomePage default/>
        </Router>
        <Footer/>
      </div>
    );
  }
}


export default connect(
  state => ({
    ready: state.storeReady
  })
)(App);
