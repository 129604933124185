import { Component } from 'preact';
import { connect } from 'react-redux';


class ConnectedFooter extends Component {
  render({ footer }) {
    const imageSrc = require('../assets/icons/Call.png').default;

    if (!footer) {
      return null;
    }

    return (
      <footer>
        <a href="tel:08006125656">
          <img src={imageSrc} />
        </a>
        <div>
          If you experience any issues press the phone icon to contact support immediately.
        </div>
      </footer>
    );
  }
}


const Footer = connect(state => {
  return { footer: state.site.footer };
})(ConnectedFooter);


export default Footer;