const APIROOT = process.env.PREACT_APP_API_ROOT;

export default {
    lists: {
        week: () => `${APIROOT}appointment/calender`,
        day: date => `${APIROOT}appointment/day/${date}`,
        autoroute: () => `${APIROOT}appointment/autoroute`,
        reorder: () => `${APIROOT}appointment/reorder`
    },
    appointments: {
        add: () => `${APIROOT}appointment/selfgen`,
        get: id => `${APIROOT}appointment/${id}`,
        update: id => `${APIROOT}appointment/update/${id}`,
        sign: () => `${APIROOT}appointment/sign`,
        activate: () => `${APIROOT}appointment/activate`,
        collect: () => `${APIROOT}appointment/collect`,
        photo: {
            add: () => `${APIROOT}appointment/photo/add`,
            delete: () => `${APIROOT}appointment/photo/delete`
        },
        payments: {
            add: () => `${APIROOT}appointment/payments/add`,
            delete: () => `${APIROOT}appointment/payments/delete`
        },
        notes: {
            add: () => `${APIROOT}appointment/note/add`
        },
        reschedule: {
            dates: () => `${APIROOT}appointment/reschedule/dates`,
            book: () => `${APIROOT}appointment/reschedule/book`,
        }
    },
    user: {
        login: () => `${APIROOT}user/login`,
        get: () => `${APIROOT}user/get`,
        logout: () => `${APIROOT}user/logout`
    },
    troubleshoot: {
        checkVersion: () => `${APIROOT}version/cp`
    },
    paymentFlow: {
        dates: () => `${APIROOT}appointment/paymentflow/dates`,
        check: () => `${APIROOT}appointment/paymentflow/check`,
        createSubscription: () => `${APIROOT}appointment/paymentflow/adelante`,
        checkCard: () => `${APIROOT}appointment/paymentflow/check-for-card`,
        complete: () => `${APIROOT}appointment/paymentflow/complete`,
        schedule: () => `${APIROOT}appointment/paymentflow/schedule`,
        noCard: () => `${APIROOT}appointment/paymentflow/no-card-attached`
    },
    address: code => `${APIROOT}external/getAddressFromPostcode?code=${code}`
};
