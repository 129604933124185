export const objectToFormData = obj => {
  let data = new FormData;

  Object.keys(obj).map(key => {
    if (Array.isArray(obj[key])) {
      obj[key].map(val =>
        data.append(`${key}[]`, val)
      );
    } else {
      data.append(key, obj[key]);
    }
  });

  return data;
};

