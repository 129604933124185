import { ActionRegister } from '../actions';
import { replaceListDay, replaceListWeek, chanReducers } from "./helpers";
import StateModel from '../model';


const ListReducer = (state = StateModel.lists, action) => {
  if (action.payload && action.payload.listType) {
    // this will also take care of the reordering
    state = replaceListDay(state, action.payload.date || null, action.payload);
    return replaceListWeek(state, action.payload);
  }

  return state;
};


const ReorderReducer = (state = StateModel.lists, action) => {
  switch (action.type) {
    case ActionRegister.lists.reorder.ROLLBACK:
    case ActionRegister.lists.reorder.REQUEST:
      return { ...state, days: { ...state.days, [action.payload.day]: action.payload.order } };

    case ActionRegister.lists.reorder.COMMIT:
      // doesnt need to do anything
      break;
  }

  return state;
};


export default chanReducers([ListReducer, ReorderReducer]);