import { createContext } from 'preact';

const AppEventBusContext = createContext(null);

const AppEventBusProvider = ({ children }) => {
	const eventBus = {
		listeners: {},
		dispatch(event) {
			if (this.listeners[event.type]) {
				this.listeners[event.type].forEach(listener => listener(event));
			}
		},
		subscribe(eventType, callback) {
			if (!this.listeners[eventType]) {
				this.listeners[eventType] = [];
			}
			this.listeners[eventType].push(callback);
		},
		unsubscribe(eventType, callback) {
			if (this.listeners[eventType]) {
				this.listeners[eventType] = this.listeners[eventType].filter(listener => listener !== callback);
			}
		},
	};

	return (
		<AppEventBusContext.Provider value={eventBus}>
			{children}
		</AppEventBusContext.Provider>
	);
};

export { AppEventBusContext, AppEventBusProvider };
