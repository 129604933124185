import { createStore, applyMiddleware } from 'redux';
import { offline } from '@redux-offline/redux-offline';
import config from '@redux-offline/redux-offline/lib/defaults';
// import { createLogger } from 'redux-logger';
import localforage from 'localforage';
import {composeWithDevTools} from 'redux-devtools-extension';
import systemPromptMiddleware from './middleware/systemPrompt';
import rootReducer from './reducers';
import effect from './effect';

if ("indexedDB" in window) {
    localforage.config({
        driver: localforage.INDEXEDDB,
        name: "ContractorConnect",
        version: 1.0
    });
} else {
    localforage.config({
        driver: localforage.LOCALSTORAGE,
        name: "ContractorConnector",
        version: 1.0
    })
}


// const logger = createLogger();

config.effect = effect;
const store = createStore(
    rootReducer,
    composeWithDevTools(
        offline({ ...config, persistOptions: {storage: localforage} }),
        applyMiddleware(systemPromptMiddleware),
        //applyMiddleware(logger),
    )
);


export default store;
