import { connect } from 'react-redux';

import { TroubleShootActions } from '../../lib/store/actions';


export default connect(null, dispatch => ({
  triggerCheck: () => {
    dispatch(TroubleShootActions.checkVersion());
  }
}))(({ triggerCheck }) => {
  triggerCheck();

  return null;
});