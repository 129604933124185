import { ActionRegister } from '../actions';
import StateModel from '../model';


const UserReducer = (state = StateModel.user, action) => {
  if (ActionRegister.user.logout.COMMIT === action.type) {
    return StateModel.user;
  } else if ('undefined' !== typeof action.payload && 'undefined' !== typeof action.payload.user) {
    return action.payload.user || StateModel.user;
  }

  return state;
};


export default UserReducer;