import { ActionRegister } from '../actions';
import { genPhotoModel, chanReducers } from "./helpers";
import StateModel from '../model';

const AppointmentModelReplaceReducer = (state = StateModel.appointments, action) => {
    if (action.payload && action.payload.model && action.payload.model.id) {
        return { ...state, [action.payload.model.id]: action.payload.model };
    }

    return state;
};


const AppointmentMiscReducer = (state = StateModel.appointments, action) => {
    let app;

    switch (action.type) {
        case ActionRegister.appointments.update.REQUEST:
            app = { ...state[action.payload.appointment_id] };
            return {
                ...state,
                [app.id]: {
                    ...app, status: action.payload.status,
                    notes: `${app.notes} <br /><br /> ${action.payload.notes}`,
                    class: `${app.class} inactive`
                }
            };

        case ActionRegister.appointments.update.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            return { ...state, [app.id]: { ...app, ...action.payload } };

        case ActionRegister.appointments.activate.REQUEST:
            app = { ...state[action.payload.appointment_id] };
            app.status = 1;
            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.activate.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            app.status = 0;
            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.collect.REQUEST:
            app = { ...state[action.payload.appointment_id] };
            for (let i = 0; i < app.carpets.length; i++) {
                if (~action.payload.ids.indexOf(~~app.carpets[i].id)) {
                    app.carpets[i].collected = 1;
                }
            }
            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.collect.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            for (let i = 0; i < app.carpets.length; i++) {
                if (~action.payload.ids.indexOf(app.carpets[i].id)) {
                    app.carpets[i].collected = 0;
                }
            }
            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.paymentFlow.check.COMMIT:
            app = { ...state[action.meta.appointment_id] };
            return { ...state, [app.id]: { ...app, canSetupPayment: !action.payload.outcome } };
    }

    return state;
};


const AppointmentSignReducer = (state = StateModel.appointments, action) => {
    let app;

    switch (action.type) {
        case ActionRegister.appointments.sign.REQUEST:
            app = { ...state[action.payload.appointment_id] };
            if (app) {
                app.sigs[action.payload.type] = genPhotoModel();
            }

            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.sign.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            if (app) {
                app[action.payload.type] = null;
            }

            return app ? { ...state, [app.id]: app } : { ...state };
    }

    return state;
};


const AppointmentPhotoReducer = (state = StateModel.appointments, action) => {
    let app;

    switch (action.type) {
        case ActionRegister.appointments.photo.add.REQUEST:
            app = { ...state[action.payload.appointment_id] };
            if (app) {
                app.photos[action.meta.i] = genPhotoModel();
            }

            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.photo.delete.REQUEST:
        case ActionRegister.appointments.photo.add.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            if (app) {
                app.photos[action.payload.i] = null;
            }

            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.photo.delete.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };
            if (app) {
                app.photos[action.payload.i] = action.payload.model;
            }

            return app ? { ...state, [app.id]: app } : { ...state };

    }

    return state;
};

const AppointmentPaymentReducer = ( state = StateModel.appointments, action) => {
    let app;

    switch (action.type) {
        case ActionRegister.appointments.payments.add.REQUEST:
            app = { ...state[action.payload.appointment_id] };

            if (app) {
                app.payments.push({ ...action.payload });
            }

            return app ? { ...state, [app.id]: app } : { ...state };
        case ActionRegister.appointments.payments.add.ROLLBACK:
            app = { ...state[action.payload.appointment_id] };

            if (app) {
                let newPayments = [];

                for (let i = 0; i < app.payments.length; i++) {
                    if (typeof app.payments[i].id != "undefined") {
                        newPayments.push(app.payments[i]);
                    }
                }

                app.payments = newPayments;

                return app ? { ...state, [app.id]: app } : { ...state };
            }
    }

    return state;
};

const AppointmentNotesReducer = (state = StateModel.appointments, action) => {
    let app;

    switch (action.type) {
        case ActionRegister.appointments.notes.add.REQUEST:
            app = { ...state[action.payload.ap_id] };

            if (app) {
                app.notes.push({
                    id: -1,
                    body: action.payload.body,
                    user_id: app.user_id,
                    updated_at: +new Date()
                });
            }

            return app ? { ...state, [app.id]: app } : state;

        case ActionRegister.appointments.notes.add.ROLLBACK:
            app = { ...state[action.payload.ap_id] };
            app.notes = app.notes.filter(note => -1 !== ~~note.id);

            return app ? { ...state, [app.id]: app } : { ...state };

        case ActionRegister.appointments.notes.add.COMMIT:
            app = action.payload.model;

            return app ? { ...state, [app.id]: app } : { ...state };
    }

    return state;
};


const AppointmentMultiReplace = (state = StateModel.appointments, action) => {
    if (action.payload && action.payload.items && "day" === action.payload.listType) {
        let newState = { ...state };
        action.payload.items.map(model => newState[model.id] = model);

        return newState;
    }

    return state;
};


const AppointmentReducer = chanReducers([
    AppointmentModelReplaceReducer,
    AppointmentMiscReducer,
    AppointmentPhotoReducer,
    AppointmentSignReducer,
    AppointmentMultiReplace,
    AppointmentPaymentReducer,
    AppointmentNotesReducer
]);


export default AppointmentReducer;
