import { combineReducers } from 'redux';
import { persistCombineReducers } from "@redux-offline/redux-offline";
import userReducer from './user';
import appointmentReducer from './appointments';
import listReducer from './lists';
import stageReducer from './stage';
import troubleShootReducer from './troubleshoot';
import routeReducer from './route';
import { chanReducers } from "./helpers";
import StateModel from '../model';
import { ActionRegister } from '../actions';


const defaultReducer = (state = StateModel, action) => {
  // clear out locatl data on logout
  if (ActionRegister.user.logout.REQUEST === action.type) {
    return { ...StateModel, storeReady: true };
  }

  return state;
};


const storeReadyReducer = (state = StateModel.storeReady, action) => {
  if ("persist/REHYDRATE" === action.type) {
    return true;
  }

  return state;
};


const siteReducer = (state = StateModel.site, action) => {
  // this may be needed in the future but right now it is just to make combine reducers shut the fuck up
  return state;
};


const finalReducer = chanReducers([
  combineReducers({
    user: userReducer,
    appointments: appointmentReducer,
    lists: listReducer,
    stage: stageReducer,
    site: siteReducer,
    storeReady: storeReadyReducer,
    defaultRoute: routeReducer
  }),
  defaultReducer,
  troubleShootReducer
]);


export default finalReducer;
export { StateModel };
