import StateModel from '../model';


export const replaceListWeek = (state, payload) => {
  if (!payload.items || 'calender' !== payload.listType) {
    return state;
  }

  return { ...state, week: payload.items };
};


export const replaceListDay = (state, day, payload) => {
  if (!day || !payload.items || 'day' !== payload.listType) {
    return state;
  }

  return { ...state, days: { ...state.days, [day]: payload.items.map(m => m.id) } };
};


export const reorderDayList = (state, payload) => {
  let newDay = [];

  for (let id in payload.order) {
    newDay.push(state.appointments[id]);
  }

  return { ...state, days: { ...state.days, [payload.day]: newDay } };
};


export const genPhotoModel = uri => {
  return {
    id : -1,
    appointment_id : '',
    uploader_id : '',
    type : '',
    path : '',
    created_at : '',
    updated_at : '',
    deleted_at : '',
    deleted_by : '',
    full_link:  uri || require('../../../assets/preloader.gif').default
  };
};


export const chanReducers = reducers => {
  if (!Array.isArray(reducers)) {
    throw new Error("chainReducers should take a single argument of type array");
  }

  return (state = StateModel, action) => {
    for (let i = 0; i < reducers.length; i++) {
      if ("function" === typeof reducers[i]) {
        state = reducers[i](state, action);
      }
    }

    return state;
  };
};