import store from '../index';
import Actions from '../actions/register';
import ApiDirectory from '../../apiDirectory';


const AppointmentActions = {
    add: data => ({
        type: Actions.appointments.add.REQUEST,
        payload: { ...data },
        meta: {
            offline: {
                effect: { url: ApiDirectory.appointments.add() },
                commit: { type: Actions.appointments.add.COMMIT }
            }
        }
    }),
    get: id => ({
        type: Actions.appointments.get.REQUEST,
        payload: { id },
        meta: {
            offline: {
                effect: { url: ApiDirectory.appointments.get(id) },
                commit: { type: Actions.appointments.get.COMMIT, meta: { id } }
            }
        }
    }),
    update: (id, data) => {
        let model = store.getState().appointments[id];
        let changes = {};

        for (let key in data) {
            changes[key] = model[key];
        }

        data.appointment_id = id;

        return {
            type: Actions.appointments.update.REQUEST,
            payload: data,
            meta: {
                offline: {
                    effect: { url: ApiDirectory.appointments.update(id), method: 'POST', body: data },
                    commit: { type: Actions.appointments.update.COMMIT, meta: { id } },
                    rollback: {
                        type: Actions.appointments.update.ROLLBACK, meta: { ...changes, id }
                    }
                }
            }
        };
    },
    sign: (id, type, data) => ({
        type: Actions.appointments.sign.REQUEST,
        payload: data,
        meta: {
            offline: {
                effect: { url: ApiDirectory.appointments.sign(), method: 'POST', body: data },
                commit: { type: Actions.appointments.sign.COMMIT, meta: { id, type } },
                rollback: { type: Actions.appointments.sign.ROLLBACK, meta: { id, type } }
            }
        }
    }),
    activate: data => ({
        type: Actions.appointments.activate.REQUEST,
        payload: data,
        meta: {
            offline: {
                effect: { url: ApiDirectory.appointments.activate(), method: 'POST', body: data },
                commit: { type: Actions.appointments.activate.COMMIT, meta: data },
                rollback: { type: Actions.appointments.activate.ROLLBACK, meta: data }
            }
        }
    }),
    collect: (ids, appointment_id) => ({
        type: Actions.appointments.collect.REQUEST,
        payload: { ids, appointment_id },
        meta: {
            offline: {
                effect: { url: ApiDirectory.appointments.collect(), method: 'POST', body: { ids: ids.join(',') } },
                commit: { type: Actions.appointments.collect.COMMIT, meta: { ids, appointment_id } },
                rollback: { type: Actions.appointments.collect.ROLLBACK, meta: { ids, appointment_id } }
            }
        }
    }),
    photo: {
        add: (data, i) => ({
            type: Actions.appointments.photo.add.REQUEST,
            payload: data,
            meta: {
                i,
                offline: {
                    effect: { url: ApiDirectory.appointments.photo.add(), method: 'POST', body: data },
                    commit: { type: Actions.appointments.photo.add.COMMIT, meta: data },
                    rollback: { type: Actions.appointments.photo.add.ROLLBACK, meta: { ...data, i } }
                }
            }
        }),
        delete: (appId, i, model) => ({
            type: Actions.appointments.photo.delete.REQUEST,
            payload: { appointment_id: appId, i },
            meta: {
                offline: {
                    effect: { url: ApiDirectory.appointments.photo.delete(), method: 'POST', body: { attachment_id: model.id } },
                    commit: { type: Actions.appointments.photo.delete.COMMIT, meta: { attachment_id: model.id } },
                    rollback: { type: Actions.appointments.photo.delete.ROLLBACK, meta: { appointment_id: appId, i, model } }
                }
            }
        })
    },
    payments: {
        add: (appId, amount, type, auth) => ({
            type: Actions.appointments.payments.add.REQUEST,
            payload: { appointment_id: appId, type, amount, auth },
            meta: {
                offline: {
                    effect: { url: ApiDirectory.appointments.payments.add(), method: 'POST', body: { appointment_id: appId, type, amount, auth } },
                    commit: { type: Actions.appointments.payments.add.COMMIT },
                    rollback: { type: Actions.appointments.payments.add.ROLLBACK, meta: { appointment_id: appId } }
                }
            }
        }),
        delete: (paymentId) => ({
            type: Actions.appointments.payments.delete.REQUEST,
            payload: { payment_id: paymentId },
            meta: {
                offline: {
                    effect: { url: ApiDirectory.appointments.payments.delete(), method: 'POST', body: { payment_id: paymentId } },
                    commit: { type: Actions.appointments.payments.delete.COMMIT },
                    rollback: { type: Actions.appointments.payments.delete.ROLLBACK, meta: { payment_id: paymentId } }
                }
            }
        })
    },
    notes: {
        add: (appId, body) => ({
            type: Actions.appointments.notes.add.REQUEST,
            payload: { ap_id: appId, body },
            meta: {
                offline: {
                    effect: { url: ApiDirectory.appointments.notes.add(), method: 'POST', body: { ap_id: appId, body } },
                    commit: { type: Actions.appointments.notes.add.COMMIT },
                    rollback: { type: Actions.appointments.notes.add.ROLLBACK, meta: { ap: appId } }
                }
            }
        })
    }
};


export default AppointmentActions;
