export default  {
  user: {name: "guest", group: 0, roles: []}, //
  lists: {
    week: {}, //
    days: {},
  },
  appointments: {},
  stage: {
    paymentFlow: {},
    carpetCollection: {},
    fitterPanel: {}
  },
  site: {
    title: 'Contractor Connector',
    sub_title: '',
    footer: true
  },
  storeReady: false,
  defaultRoute: {
    start: null,
    end: null
  }
};