import { h, Component } from 'preact';


class PromptHelper
{
    queue     = [];
    container = null;

    alert = (message, conf) => {
        conf = conf || {};

        return new Promise((accept, reject) => {
            this.queue.push(new PromptJob(
                PromptHelper.ALERT,
                message,
                conf.callback || null,
                conf.timeout  || 5000,
                accept
            ));

            this.triggerPrompt();
        });
    };

    error = (message, conf) => {
        conf = conf || {};

        return new Promise((accept, reject) => {
            this.queue.push(new PromptJob(
                PromptHelper.ERROR,
                message,
                conf.callback || null,
                conf.timeout  || 5000,
                accept
            ));

            this.triggerPrompt();
        });
    };

    input = (message, callback, conf = {}) => {
        return new Promise((accept, reject) => {
            this.queue.push(new PromptJob(
                PromptHelper.INPUT,
                message,
                callback     || conf.callback || null,
                conf.timeout || null,
                accept,
                conf.defaultText || null
            ));

            this.triggerPrompt();
        });
    };

    confirm = (message, callback, conf) => {
        conf = conf || {};

        return new Promise((accept, reject) => {
            this.queue.push(new PromptJob(
                PromptHelper.CONFIRM,
                message,
                callback     || conf.callback || null,
                conf.timeout || null,
                accept
            ));

            this.triggerPrompt();
        });
    };

    clearPrompt = () => {
        this.queue.shift();

        this.triggerPrompt(true);
    };

    triggerPrompt = force => {
        if (this.container && (this.queue.length < 2 || force)) {
            this.container.trigger();
        }
    };

    activePrompt = () => {
        return this.queue[0] || null;
    };
}

class PromptJob
{
    type        = null;
    message     = null;
    callback    = null;
    timeout     = null;
    accept      = null;
    defaultText = null;

    constructor(type, message, callback, timeout, accept, defaultText) {
        this.type        = type;
        this.message     = message;
        this.callback    = callback;
        this.timeout     = timeout;
        this.accept      = accept;
        this.defaultText = defaultText;
    }
}

PromptHelper.ERROR   = 1;
PromptHelper.ALERT   = 2;
PromptHelper.INPUT   = 3;
PromptHelper.CONFIRM = 4;


const promptHelper = new PromptHelper();


class SystemPromptContainer extends Component
{
    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            last: +new Date
        };

        promptHelper.container = this;
    }

    trigger = () => {
        this.setState({last: +new Date});
    };

    handleComplete = (response) => {
        const job = promptHelper.activePrompt();
        if (job && job.callback) {
            job.callback(response);
        }
        promptHelper.clearPrompt();
    };

    handleCancel = () => {
        promptHelper.clearPrompt();
    };

    render() {
        const job = promptHelper.activePrompt();
        if (!job) {
            return null;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (job.timeout > 0) {
            this.timeout = setTimeout(() => {
                this.handleComplete();
            }, job.timeout);
        }

        let dismissible = false;
        let prompt = null;
        switch (job.type) {
            default:
            case PromptHelper.ALERT:
                dismissible = true;
                prompt = (<SystemAlert job={job} onComplete={this.handleComplete} onCancel={this.handleCancel}/>);
                break;
            case PromptHelper.ERROR:
                dismissible = true;
                prompt = (<SystemError job={job} onComplete={this.handleComplete} onCancel={this.handleCancel}/>);
                break;
            case PromptHelper.INPUT:
                dismissible = false;
                prompt = (<SystemInput job={job} onComplete={this.handleComplete} onCancel={this.handleCancel}/>);
                break;
            case PromptHelper.CONFIRM:
                dismissible = false;
                prompt = (<SystemConfirm job={job} onComplete={this.handleComplete} onCancel={this.handleCancel}/>);
                break;
        }

        return (
            <div class="prompt-cover" onclick={dismissible ? this.handleCancel : null}>
                {prompt}
            </div>
        )
    }
}


class SystemPromptBase extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            outcome: null
        };
    }

    resetState = () => {
        this.setState({outcome: null});
    };

    setOutcome = outcome => {
        this.setState({ outcome });
    };

    resolvePromise = () => {
        this.props.job.accept(this.state.outcome);
    };

    handleComplete = e => {
        e.stopPropagation();
        this.setOutcome(true);
        if ("function" === typeof this.props.onComplete) {
            this.props.onComplete(this.state.outcome);
        }

        this.resolvePromise();
        this.resetState();
    };

    handleCancel = e => {
        e.stopPropagation();
        this.setOutcome(false);
        if ("function" === typeof this.props.onCancel) {
            this.props.onCancel(this.state.outcome);
        }
        this.resolvePromise();
        this.resetState();
    };
}


class SystemError extends SystemPromptBase
{
    render({ job, onComplete }) {
        return (
            <div class="system-prompt error" onClick={this.handleComplete}>
                <div class="message" dangerouslySetInnerHTML={{__html: job.message}} />
            </div>
        );
    }
}


class SystemAlert extends SystemPromptBase
{
    render({ job, onComplete }) {
        return (
            <div class="system-prompt alert"  onClick={this.handleComplete}>
                <div class="message" dangerouslySetInnerHTML={{__html: job.message}} />
            </div>
        );
    }
}


class SystemInput extends SystemPromptBase
{
    constructor(props) {
        super(props)
        this.state = {
            outcome: this.props.job.defaultText || null
        };
    }

    input = null;

    handleChange = ({ target }) => {
        this.setOutcome(target.value);
    };

    handleComplete = e => {
        e.stopPropagation();
        this.props.onComplete(this.state.outcome);
        this.resolvePromise();
        this.resetState();
    };

    handleCancel = e => {
        e.stopPropagation();
        this.setOutcome('');
        this.props.onCancel();
        this.resolvePromise();
        this.resetState();
    };

    componentDidMount() {
        this.input.focus();
    }

    render({ job, onCancel, onComplete }, { outcome }) {
        return (
            <div class="system-prompt input">
                <div class="message" dangerouslySetInnerHTML={{__html: job.message}} />
                {/*<form onSubmit={e => e.preventDefault()}>*/}
                <div class="input-spacer">
                    <input type="text" name="prompt-input" onChange={this.handleChange} value={outcome} ref={input => this.input = input} placeholder="Input your text..."/>
                </div>
                <div class="button-row">
                    <button class="cancel red button" onClick={this.handleCancel}>Cancel</button>
                    <button class="submit green button" onClick={this.handleComplete}>Submit</button>
                </div>
                {/*</form>*/}
            </div>
        );
    }
}


class SystemConfirm extends SystemPromptBase
{
    handleComplete = e => {
        e.stopPropagation();
        const outcome = true;
        this.props.job.accept(outcome);
        if ("function" === typeof this.props.onComplete) {
            this.props.onComplete(outcome);
        }
        this.resetState();
    };

    handleCancel = e => {
        e.stopPropagation();
        const outcome = false;
        this.props.job.accept(outcome);
        if ("function" === typeof this.props.onCancel) {
            this.props.onCancel(outcome);
        }
        this.resetState();
    };


    render({ job, onCancel, onComplete }, { outcome }) {
        return (
            <div class="system-prompt input">
                <div class="message" dangerouslySetInnerHTML={{__html: job.message}} />
                {/*<form onSubmit={e => e.preventDefault()}>*/}
                <div class="button-row">
                    <button class="cancel red button" onClick={this.handleCancel}>Cancel</button>
                    <button class="submit green button" onClick={this.handleComplete}>Confirm</button>
                </div>
                {/*</form>*/}
            </div>
        );
    }
}

export default SystemPromptContainer;
export { SystemPromptContainer, promptHelper };
