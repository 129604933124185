import { Component, h } from 'preact';
import { route, getCurrentUrl } from 'preact-router';
import { connect } from 'react-redux';

import store from './store';
import { StaffRoles, UserGroups } from './user';


const accessRoles = {
  staff: {
    roles: [StaffRoles.SALES, StaffRoles.FITTER],
    groups: [UserGroups.CONTRACTOR, UserGroups.OFFICE_STAFF, UserGroups.ROOT_ADMIN]
  },
  guest: {roles: [StaffRoles.ANY], groups: [UserGroups.GUEST]},
  fitter:{roles: [StaffRoles.FITTER], groups: [UserGroups.CONTRACTOR, UserGroups.OFFICE_STAFF]},
  sales: {roles: [StaffRoles.SALES], groups: [UserGroups.CONTRACTOR, UserGroups.OFFICE_STAFF]},
};

const AccessHelpers = {
  check: (allowedGroups, allowedRoles, authData, selectedRole = null) => {
    // check the users group is allowed
    if (!~allowedGroups.indexOf(~~authData.group)) {
      return false;
    }

    if (StaffRoles.ANY === allowedRoles[0]) {
      return true;
    }

    // check the user has an allowed role and if their selected role is allowed
    for (let i = 0; i < authData.roles.length; i++) {
        if (~allowedRoles.indexOf(~~authData.roles[i])) {
            return true;
        }
    }

      return false;
    // return !!authData.roles.filter(val => !!~allowedRoles.indexOf(~~val))
    //   && (!selectedRole || !!~allowedRoles.indexOf(~~selectedRole));
  },

  fetch:() => {
    return store.getState().user || {};
  }
};


const AccessWrapper = connect((state, props) => ({
  user: state.user,
  selectedRole: state.selectedRole
}))(class ConnectedAccessWrapper extends Component {
  render({ user, component, roles, groups, onFail, selectedRole, children, ...rest}) {
    const isAuthorized = AccessHelpers.check(groups, roles, user, selectedRole);

    if (isAuthorized) {
      if (component) {
        return h(component, rest);
      }
      return children;
    }

    if ("function" === typeof onFail) {
      return onFail();
    }

    if ("string" === typeof onFail) {
      route(onFail, true);
    }

    return null;
  }
});


const routeOthers = () => {
  if (AccessHelpers.check(accessRoles.guest.groups, accessRoles.guest.roles, AccessHelpers.fetch()) && '/' !== getCurrentUrl()) {
    route('/');
  }
};


const GuestAccess  = { ...accessRoles.guest };
const FitterAccess = { ...accessRoles.fitter, onFail: routeOthers};
const SalesAccess  = { ...accessRoles.sales, onFail: routeOthers};
const StaffAccess  = { ...accessRoles.staff, onFail: routeOthers};

export { GuestAccess, FitterAccess, SalesAccess, StaffAccess, accessRoles, AccessWrapper };
