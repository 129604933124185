import { promptHelper } from "../../../components/common/SystemPrompt";


export default store => next => action => {
  let message = "";

  try {
    if (!action.payload) {
      throw new Error('_');
    }
    if ("string" === typeof action.payload.error) {
      message = action.payload.error;
    }

    if (action.payload.errors && "string" === typeof action.payload.errors.main) {
      message = action.payload.errors.main;
    }

    if (message) {
      action.payload.outcome ? promptHelper.alert(message) : promptHelper.error(message);
    }
  } catch(e) {}

  next(action);
};