import { h } from 'preact';

const Loading = ({ text = "Loading" }) => {
  const imageSrc = require('../../assets/preloader.gif').default;

  return (
    <div class="loading">
      <img src={imageSrc} alt="Loading" />
      <br />
      <strong>{text}</strong>
    </div>
  );
};

export default Loading;
