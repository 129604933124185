import Actions from './register';
import ApiDirectory from '../../apiDirectory';


const UserActions = {
  login: data => ({
    type: Actions.user.login.REQUEST,
    payload: data,
    meta: {
      offline: {
        effect: {url: ApiDirectory.user.login(), method: 'POST', body: data},
        commit: {type: Actions.user.login.COMMIT, meta: {}}
      }
    }
  }),
  get: () => ({
    type: Actions.user.get.REQUEST,
    payload: {},
    meta: {
      offline: {
        effect: {url: ApiDirectory.user.get(), method: 'GET'},
        commit: {type: Actions.user.get.COMMIT, meta: {}}
      }
    }
  }),
  logout: () => ({
    type: Actions.user.logout.REQUEST,
    payload: {},
    meta: {
      offline: {
        effect: {url: ApiDirectory.user.logout(), method: 'POST', body: {}},
        commit: {type: Actions.user.logout.COMMIT, meta: {}}
      }
    }
  })
};


export default UserActions;
