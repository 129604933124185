import { ActionRegister } from '../actions';
import StateModel from '../model';
import { promptHelper } from "../../../components/common/SystemPrompt";


const checkVersion = payload => {
  // do nothing if the response isnt valid
  if (!payload || !payload.version || !payload.bundle || !payload.style) {
    return true;
  }

  let existing = JSON.parse(localStorage.getItem('ccVersionCache') || '{}');

  localStorage.setItem('ccVersionCache', JSON.stringify({
    version: payload.version,
    bundle: payload.bundle,
    style: payload.style
  }));

  return existing
    && existing.version === payload.version
    && existing.bundle === payload.bundle
    && existing.style === payload.style
};


const deleteCacheStorage = () => {
  return caches.keys()
    .then(keys => {
      for (let i = 0; i < keys.length; i++) {
        if (~keys[i].indexOf('sw-precache')) {
          return caches.delete(keys[i])
        }
      }

      return true;
    });
};


const removeServiceWorker = () => {
  try {
    return navigator.serviceWorker.getRegistration()
        .then(function(reg) {
          if (reg) {
            return reg.unregister()
                .then(function() {
                  return true;
                });
          } else {
            return true;
          }
        });
  } catch (e) {

  }

  return new Promise((_, reject) => {
    reject();
  })
};


const forceReloadPage = () => {
  window.location.reload(true);
  return true;
};


// not really a reducer but it handles the actions
export default (state = StateModel, action) => {
  switch (action.type) {
    case ActionRegister.troubleshoot.hardReload.REQUEST:
      break;

    case ActionRegister.troubleshoot.versionCheck.COMMIT:
      if (checkVersion(action.payload)) {
        return state;
      }
      /* fall through to clear data */
    case ActionRegister.troubleshoot.hardReload.COMMIT:
      removeServiceWorker()
        .then(deleteCacheStorage)
        .then(forceReloadPage)
        .then(() => {
          promptHelper.alert("Local data cleared");
        });

      return StateModel;

    case ActionRegister.troubleshoot.versionCheck.REQUEST:
      promptHelper.alert("Checking app version");
      break;


  }

  return state;
};
