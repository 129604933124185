import './style/style.less';
import { Provider } from 'react-redux';
import store from './lib/store';
import App from './components/App';
import { AppEventBusProvider } from './lib/context/AppEventBus';

// const setGlobalVars = require('indexeddbshim');
//
// global.window = global; // We'll allow ourselves to use `window.indexedDB` or `indexedDB` as a global
// setGlobalVars(); // See signature below
// setTimeout(() => {
//     store.dispatch({type: "persist/REHYDRATE"})
// }, 5000);

export default () => (
  <Provider store={store}>
    <AppEventBusProvider>
      <App />
    </AppEventBusProvider>
  </Provider>
);
