import StateModel from '../model';
import { ActionRegister } from '../actions';

export default (state = StateModel.stage, action) => {
    switch (action.type) {
        case ActionRegister.stage.change.REQUEST:
            const { type, stage, meta } = action.payload;
            return { 
                ...state, 
                [type]: {
                    stage,
                    meta
                }
            };

        case ActionRegister.paymentFlow.createSubscription.COMMIT:
            return {
                ...state,
                adelanteFlow: {
                    ...state.adelanteFlow,
                    meta: {
                        ...state.adelanteFlow.meta,
                        link: action.payload.link || "",
                        outcome: action.payload.outcome || false,
                        customer_ref: action.payload.cr || "",
                        plan_ref: action.payload.pr || ""
                    }
                }
            };

        case ActionRegister.paymentFlow.complete.COMMIT:
            return {
                ...state,
                adelanteFlow: {
                    ...state.adelanteFlow,
                    meta: {
                        ...state.adelanteFlow.meta,
                        complete: action.payload.outcome || false
                    }
                }
            };

        case ActionRegister.paymentFlow.clear.COMMIT:
            return { ...state, adelanteFlow: {} };
    }


    return state;
};

