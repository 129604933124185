import Actions from './register';
import ApiDirectory from '../../apiDirectory';


const ListActions = {
  week: () =>  ({
    type: Actions.lists.week.REQUEST,
    payload: {},
    meta: {
      offline: {
        effect: {url: ApiDirectory.lists.week(), method: 'GET'},
        commit: {type: Actions.lists.week.COMMIT, meta: {}}
      }
    }
  }),
  day: date =>  ({
    type: Actions.lists.day.REQUEST,
    payload: {},
    meta: {
      offline: {
        effect: {url: ApiDirectory.lists.day(date), method: 'GET'},
        commit: {type: Actions.lists.day.COMMIT, meta: { date }}
      }
    }
  }),
  autoroute: (start, end, date) => ({
    type: Actions.lists.autoroute.REQUEST,
    payload: { start, end, date },
    meta: {
      offline: {
        effect: {url: ApiDirectory.lists.autoroute(), method: 'POST', body: { start, end, date }},
        commit: {type: Actions.lists.autoroute.COMMIT},
        rollback: {type: Actions.lists.autoroute.ROLLBACK}
      }
    }
  }),
  reorder: (newOrder, origOrder, day) => ({
    type: Actions.lists.reorder.REQUEST,
    payload: { order: newOrder, day },
    meta: {
      offline: {
        effect: {url: ApiDirectory.lists.reorder(), method: 'POST', body: {order: newOrder.join(',')}},
        commit: {type: Actions.lists.reorder.COMMIT, meta: {}},
        rollback: {type: Actions.lists.reorder.ROLLBACK, meta: { order: origOrder, day }}
      }
    }
  }),
};


export default ListActions;
