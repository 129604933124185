export const UserGroups = {
  GUEST: 0,
  OFFICE_STAFF: 1,
  CONTRACTOR: 3,
  USER: 5,
  ROOT_ADMIN: 100
};

export const StaffRoles = {
  SALES: 27,
  FITTER: 28,
  ANY: -1
};
