import { h, Component } from 'preact';
import { connect } from 'react-redux';

import { accessRoles, AccessWrapper } from "../lib/access"
import { TroubleShootActions } from '../lib/store/actions';

import { Nav, navHelper } from "./Nav.jsx"
import { SystemPromptContainer, promptHelper } from "./common/SystemPrompt"


const TroubleShooter = connect(null, dispatch => ({
  triggerTroubleShooting: () => {
    dispatch(TroubleShootActions.hardReload());
  }
}))(class ConnectedTroubleShooter extends Component {
  triggerTroubleShooter = () => {
    let existing = JSON.parse(localStorage.getItem('ccVersionCache') || '{}');
    promptHelper.confirm("Bundle: " + existing.bundle
      + " <br/>Version: " + existing.version
      + " <br/>Are you sure you want to run the trouble shooter? This will clear all your local data!")
      .then(outcome => {
        if (!outcome) {
          throw new Error("user cancelled flow");
        }

        // clear session and local data
        this.props.triggerTroubleShooting();

        return true;
      }).catch(e => {});
  };

  render() {
    return (
      <article id="tshooter" onClick={this.triggerTroubleShooter} dangerouslySetInnerHTML={{__html: '&#8253;'}}/>
    );
  };
});


const Header = connect(state => {
  return { ...state.site, user: state.user };
})(class ConnectedHeader extends Component {
  componentWillMount() {
    navHelper.component = this;
    this.setState({linkText: '/' === navHelper.link ? 'Logout' : 'Back'});
  }

  componentWillUnmount()  {
    navHelper.component = null;
  }

  render ({ title, sub_title, user }, { linkText }) {
    return (
      <header>
        <div class="header">
          <h1>{title}</h1>
          <h2>{sub_title || (user ? user.name : null) || 'Contractor Connector'}</h2>
        </div>
        <AccessWrapper component={Nav} text={linkText} { ...accessRoles.staff }/>
        <TroubleShooter />
        <SystemPromptContainer/>
      </header>
    )
  }
});


export default Header;