import Actions from './register';
import ApiDirectory from '../../apiDirectory';


const PaymentFlowActions = {
    check: appointment_id => ({
        type: Actions.paymentFlow.check.REQUEST,
        payload: { appointment_id },
        meta: {
            offline: {
                effect: {url: ApiDirectory.paymentFlow.check(), body: { appointment_id }, method: 'POST'},
                commit: {type: Actions.paymentFlow.check.COMMIT, meta: { appointment_id }}
            }
        }
    }),

    createSubscription: data => ({
        type: Actions.paymentFlow.createSubscription.REQUEST,
        payload: { ...data },
        meta: {
            offline: {
                effect: {url: ApiDirectory.paymentFlow.createSubscription(), body: data, method: 'POST'},
                commit: {type: Actions.paymentFlow.createSubscription.COMMIT, meta: data}
            }
        }
    }),

    complete: data => ({
        type: Actions.paymentFlow.complete.REQUEST,
        payload: { ...data },
        meta: {
            offline: {
                effect: {url: ApiDirectory.paymentFlow.complete(), body: data, method: 'POST'},
                commit: {type: Actions.paymentFlow.complete.COMMIT, meta: data}
            }
        }
    }),

    schedule: data => ({
        type: Actions.paymentFlow.schedule.REQUEST,
        payload: data,
        meta: {
            offline: {
                effect: {url: ApiDirectory.paymentFlow.schedule(), body: data, method: 'POST'},
                commit: {type: Actions.paymentFlow.schedule.COMMIT, meta: data}
            }
        }
    }),

    clear: () => ({
        type: Actions.paymentFlow.clear.COMMIT,
        payload: {}
    })
};


export default PaymentFlowActions;
