import { getCurrentUrl, Link, route } from 'preact-router';


const Nav = ({ text }) => {
  return (
    <nav>
      <Link href='javascript:void(0)' onClick={navHelper.triggerLink}>
        <img src={require('../assets/icons/Exit.png').default} />
        <span>{text}</span>
      </Link>
    </nav>
  );
};


class NavHelper
{
  link      = '/';
  component = null;


  setLink = (link) => {
    let text = 'Back';
    if ('/' === getCurrentUrl()) {
      text = 'Logout';
      this.link = '/logout';
    } else {
      this.link = link;
    }

    this.component.setState({
      linkText: text
    });

  };

  triggerLink = () => {
    if ('function' === typeof this.link) {
      this.link();
    } else {
      route(this.link || '/', true);
    }
  };
}


const navHelper = new NavHelper;

export default Nav;
export { Nav, navHelper };