import { ActionRegister } from '../actions'
import StateModel from '../model';


const RouteReducer = (state = StateModel.defaultRoute, action) => {
  if (ActionRegister.route.setDefault.COMMIT === action.type) {
    return action.payload;
  }

  return state;
};


export default RouteReducer;