const Actions = {
    appointments: {
        add: {
            REQUEST: 'APPOINTMENT.ADD.REQUEST',
            COMMIT: 'APPOINTMENT.ADD.COMMIT'
        },
        get: {
            REQUEST: 'APPOINTMENT.GET.REQUEST',
            COMMIT: 'APPOINTMENT.GET.COMMIT'
        },
        update: {
            REQUEST: 'APPOINTMENT.UPDATE.REQUEST',
            COMMIT: 'APPOINTMENT.UPDATE.COMMIT',
            ROLLBACK: 'APPOINTMENT.UPDATE.ROLLBACK'
        },
        sign: {
            REQUEST: 'APPOINTMENT.SIGN.REQUEST',
            COMMIT: 'APPOINTMENT.SIGN.COMMIT',
            ROLLBACK: 'APPOINTMENT.SIGN.ROLLBACK'
        },
        activate: {
            REQUEST: 'APPOINTMENT.ACTIVATE.REQUEST',
            COMMIT: 'APPOINTMENT.ACTIVATE.COMMIT',
            ROLLBACK: 'APPOINTMENT.ACTIVATE.ROLLBACK'
        },
        collect: {
            REQUEST: 'APPOINTMENT.COLLECT.REQUEST',
            COMMIT: 'APPOINTMENT.COLLECT.COMMIT',
            ROLLBACK: 'APPOINTMENT.COLLECT.ROLLBACK'
        },
        photo: {
            add: {
                REQUEST: 'APPOINTMENT.PHOTO.ADD.REQUEST',
                COMMIT: 'APPOINTMENT.PHOTO.ADD.COMMIT',
                ROLLBACK: 'APPOINTMENT.PHOTO.ADD.ROLLBACK'
            },
            delete: {
                REQUEST: 'APPOINTMENT.PHOTO.DELETE.REQUEST',
                COMMIT: 'APPOINTMENT.PHOTO.DELETE.COMMIT',
                ROLLBACK: 'APPOINTMENT.PHOTO.DELETE.ROLLBACK'
            }
        },
        payments: {
            add: {
                REQUEST: 'APPOINTMENT.PAYMENTS.ADD.REQUEST',
                COMMIT: 'APPOINTMENT.PAYMENTS.ADD.COMMIT',
                ROLLBACK: 'APPOINTMENT.PAYMENTS.ADD.ROLLBACK'
            },
            delete: {
                REQUEST: 'APPOINTMENT.PAYMENTS.DELETE.REQUEST',
                COMMIT: 'APPOINTMENT.PAYMENTS.DELETE.COMMIT',
                ROLLBACK: 'APPOINTMENT.PAYMENTS.DELETE.ROLLBACK'
            }
        },
        notes: {
            add: {
                REQUEST: 'APPOINTMENT.NOTES.ADD.REQUEST',
                COMMIT: 'APPOINTMENT.NOTES.ADD.COMMIT',
                ROLLBACK: 'APPOINTMENT.NOTES.ADD.ROLLBACK'
            }
        }
    },
    paymentFlow: {
        clear: {
            COMMIT: 'STAGE.PAYMENT.CLEAR.COMMIT',
        },
        check: {
            REQUEST: 'STAGE.PAYMENT.CHECK.REQUEST',
            COMMIT: 'STAGE.PAYMENT.CHECK.COMMIT'
        },
        complete: {
            REQUEST: 'STAGE.PAYMENT.COMPLETE.REQUEST',
            COMMIT: 'STAGE.PAYMENT.COMPLETE.COMMIT'
        },
        createSubscription: {
            REQUEST: 'STAGE.PAYMENT.CREATE.REQUEST',
            COMMIT: 'STAGE.PAYMENT.CREATE.COMMIT'
        },
        schedule: {
            REQUEST: 'STAGE.PAYMENT.SCHEDULE.REQUEST',
            COMMIT: 'STAGE.PAYMENT.SCHEDULE.COMMIT'
        }
    },
    stage: {
        change: {
            REQUEST: 'STAGE.CHANGE.REQUEST'
        }
    },
    lists: {
        week: {
            REQUEST: 'LIST.WEEK.REQUEST',
            COMMIT: 'LIST.WEEK.COMMIT'
        },
        day: {
            REQUEST: 'LIST.DAY.REQUEST',
            COMMIT: 'LIST.DAY.COMMIT'
        },
        autoroute: {
            REQUEST: 'APPOINTMENT.AUTOROUTE.REQUEST',
            COMMIT: 'APPOINTMENT.AUTOROUTE.COMMIT',
            ROLLBACK: 'APPOINTMENT.AUTOROUTE.ROLLBACK'
        },
        reorder: {
            REQUEST: 'APPOINTMENT.REORDER.REQUEST',
            COMMIT: 'APPOINTMENT.REORDER.COMMIT',
            ROLLBACK: 'APPOINTMENT.REORDER.ROLLBACK'
        }
    },
    user: {
        login: {
            REQUEST: 'USER.LOGIN.REQUEST',
            COMMIT: 'USER.LOGIN.COMMIT'
        },
        get: {
            REQUEST: 'USER.GET.REQUEST',
            COMMIT: 'USER.GET.COMMIT'
        },
        logout: {
            REQUEST: 'USER.LOGOUT.REQUEST',
            COMMIT: 'USER.LOGOUT.COMMIT'
        }
    },
    troubleshoot: {
        hardReload: {
            REQUEST: 'TSHOOT.HARDRELOAD.REQUEST',
            COMMIT: 'TSHOOT.HARDRELOAD.COMMIT'
        },
        versionCheck: {
            REQUEST: 'TSHOOT.VERSIONCHECK.REQUEST',
            COMMIT: 'TSHOOT.VERSIONCHECK.COMMIT'
        }
    },
    route: {
        setDefault: {
            COMMIT: 'ROUTE.SETDEFAULT.COMMIT'
        }
    }
};


export default Actions;
