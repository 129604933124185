import Actions from './register';


const RouteActions = {
  setDefaults: (start, end) => ({
    type: Actions.route.setDefault.COMMIT,
    payload: {
      start, end
    }
  })
};


export default RouteActions;