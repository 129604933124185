import { objectToFormData } from "./helpers";


export const NetworkError = (response = '', status) => {
  this.name     = "NetworkError";
  this.status   = status;
  this.response = response;
};
NetworkError.prototype = Error;


const parseResponse = res => {
  const ctype = res.headers.get('content-type') || null;

  return ctype && ~ctype.indexOf('json') ?
    res.json() :
    res.text();
};

export default (effect, action) => {
  const { url, ...options } = effect;

  if (options.body
      && options.body instanceof Object
      && !(options.body instanceof FormData)
  ) {
      options.body = objectToFormData(options.body);
  }

  return fetch(url, { credentials: 'include', mode: 'cors', ...options })
    .then(res => {
      if (res.ok) {
        return parseResponse(res);
      }

      return parseResponse(res)
        .then(body => {
          throw new NetworkError(body, res.status);
        });
    });
};
